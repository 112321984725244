import React from "react";
import {graphql} from "gatsby";
import {isEmpty, kebabCase} from "lodash";
import Img from "components/image";
import DefaultLayout from "components/layout/default";
import {IMAGE_BACKGROUND_PROPS} from "constants/index";
import style from "styles/pages/products.module.css";
import layoutStyle from "styles/layout.module.css";
import radiusTopCapImage from "images/product-radius-top-cap.svg";
import radiusBottomCapImage from "images/product-radius-bottom-cap.svg";
import storyfierImage from "images/product-storyfier.svg";
import trainerImage from "images/product-trainer.svg";
import wardenImage from "images/product-warden.svg";

function ProductsItemTemplate(props) {
  let pageContent = props.data.pageContent.childProductPagesJson;
  let [mainImageLoaded, setMainImageLoaded] = React.useState(false);
  let mainImageRef = React.useRef();

  React.useEffect(() => {
    setMainImageLoaded(mainImageRef.current.complete);
  }, [mainImageRef]);

  function handleMainImageLoad() {
    setMainImageLoaded(true);
  }

  function renderVerticalLine() {
    let height = "0%";
    if (mainImageLoaded) height = "64vh";
    return <div className={`${style.verticalLine} absolute top-0 w-px bg-white hidden md:block`}
      style={{height}}/>;
  }

  function renderSection1() {
    let mainImage;
    if (/storyfier/.test(props.pageContext.name)) mainImage = storyfierImage;
    if (/trainer/.test(props.pageContext.name)) mainImage = trainerImage;
    if (/warden/.test(props.pageContext.name)) mainImage = wardenImage;
    return <section className="relative min-h-screen">
      <div className="container mx-auto px-8 pt-4 pb-16 md:py-32 z-10 relative">
        <div className="text-center px-8 md:px-0">
          <img ref={mainImageRef} className="inline-block pointer-events-none"
            src={mainImage} width={340}
            alt={pageContent.section1.headerText}
            onLoad={handleMainImageLoad}/>
        </div>
        <div className="relative flex">
          {renderVerticalLine()}
          <div className="md:w-1/2 flex-none"/>
          <div className="ml-0 md:ml-16">
            <h3 className = "font-bold text-4xl lg:text-6xl leading-tight mt-4">
              {pageContent.section1.headerText}
            </h3>
            <p>
              {pageContent.section1.subHeaderText}
            </p>
            <div>
              <a href={pageContent.section1.ctaUrl} className="btn btn-orange mt-6 inline-block">
                {pageContent.section1.ctaText}
              </a>
            </div>
          </div>
        </div>
      </div>
      <Img {...IMAGE_BACKGROUND_PROPS} objectFit="contain" alt="Background"
        fluid={props.data.backgroundImage.childImageSharp.fluid}/>
    </section>;
  }

  function renderSection2() {
    let {image} = pageContent.section2;
    if (image && image.childImageSharp) {
      image = <Img fluid={image.childImageSharp.fluid} alt={image.name}
        style={{pointerEvents: "none"}}/>;
    } else {
      image = null;
    }
    let section2CtaButton;
    if (isEmpty(pageContent.section2.ctaUrl) === false ) {
      if (isEmpty(pageContent.section2.ctaText) === false ) {
        section2CtaButton = <a href={pageContent.section2.ctaUrl}
          className="btn btn-orange mt-6 inline-block">
          {pageContent.section2.ctaText}
        </a>;
      }
    }
    let headerText;
    if (isEmpty(pageContent.section2.headerText) === false) {
      headerText = <h2 className="font-bold text-xl text-center leading-tight uppercase">
        {pageContent.section2.headerText}
      </h2>;
    }
    let subHeaderText;
    if (isEmpty(pageContent.section2.subHeaderText) === false) {
      subHeaderText = <h3 className="font-bold text-4xl lg:text-6xl text-center leading-tight mt-4 whitespace-pre-wrap">
        {pageContent.section2.subHeaderText}
      </h3>;
    }
    let content;
    if (pageContent.section2.items != null) {
      content = renderItems();
    } else {
      content = <React.Fragment>
        <div className="flex flex-col md:flex-row mt-16 px-0 lg:px-24">
          <div className="w-full md:w-1/2 mb-6 md:mb-0">{image}</div>
          <div className="w-full md:w-1/2 md:pl-8">
            <p>{pageContent.section2.copy}</p>
            {section2CtaButton}
          </div>
        </div>
        {renderSection3()}
      </React.Fragment>;
    }
    return <React.Fragment>
      <img className="w-full z-1 pointer-events-none"
        src={radiusTopCapImage} alt="Background"/>
      <section className="relative z-10 -mt-32 overflow-hidden">
        <div className="bg-white absolute w-full" style={{top: "127px", bottom: "127px"}}/>
        <div className="container mx-auto px-8 py-24 lg:py-0 relative text-black">
          {headerText}
          {subHeaderText}
          {content}
        </div>
      </section>
      <img className="w-full z-1 -mt-32 pointer-events-none"
        src={radiusBottomCapImage} alt="Background"/>
    </React.Fragment>;
  }

  function renderSection3() {
    if (isEmpty(pageContent.section3)) return null;
    return <React.Fragment>
      <h3 className="font-serif italic text-4xl lg:text-6xl text-center leading-tight mt-48 mb-12">
        {pageContent.section3.headerText}
      </h3>
      <p className="max-w-2xl mx-auto mt-8 text-center whitespace-pre-wrap">
        {pageContent.section3.copy}
      </p>
      <div className="text-center">
        <a href={pageContent.section3.ctaUrl} className="btn btn-orange mt-6 inline-block">
          {pageContent.section3.ctaText}
        </a>
      </div>
    </React.Fragment>;
  }

  function renderItems() {
    let {items} = pageContent.section2;
    if (isEmpty(items)) return null;
    return items.map((item, index) => {
      let reverseClassName, marginClassName;
      if ((index + 1) / 2 === 1) {
        reverseClassName = "md:flex-row-reverse";
      }
      if (index + 1 !== items.length) {
        marginClassName = "mb-48";
      }
      let features;
      if (item.features) {
        features = item.features.map((featureItem) => {
          return <div key={`feature-${kebabCase(featureItem.headerText)}`} className="mb-4">
            <h3>
              <strong>{featureItem.headerText}</strong>
            </h3>
            <p>{featureItem.descriptionText}</p>
          </div>;
        });
      }
      let ctaHintText;
      if (item.ctaHintText) {
        ctaHintText = <div className="mt-2 text-sm">{item.ctaHintText}</div>;
      }
      let {image} = item;
      if (image && image.childImageSharp) {
        image = <Img fluid={image.childImageSharp.fluid}
          alt={image.name}
          className="mb-8 md:mb-0"
          style={{pointerEvents: "none"}}/>;
      } else {
        image = null;
      }
      return <div key={`item-${kebabCase(item.headerText)}`} className={marginClassName}>
        <h3 className="font-bold text-4xl lg:text-6xl text-center leading-tight mt-4 whitespace-pre-wrap">
          {item.headerText}
        </h3>
        <div className={`flex flex-col md:flex-row ${reverseClassName} mt-16 px-0 lg:px-24`}>
          <div className="w-full md:w-1/2 mb-6 md:mb-0">{image}</div>
          <div className="md:w-8"/>
          <div className="w-full md:w-1/2">{features}</div>
        </div>
        <div className="text-center">
          <a href={item.ctaUrl}
            className="btn btn-orange mt-6 inline-block">
            {item.ctaText}
          </a>
          {ctaHintText}
        </div>
      </div>;
    });
  }

  return <DefaultLayout location={props.location}
    title={pageContent.title} description={pageContent.description}>
    <div className={layoutStyle.headerPlaceholder}/>
    {renderSection1()}
    {renderSection2()}
    <div className="pb-48"/>
  </DefaultLayout>;
}

export default React.memo(ProductsItemTemplate);

export const QUERY = graphql`
  query ProductsItemTemplateQuery($fileId: String!) {
    pageContent: file(id: {eq: $fileId}) {
      childProductPagesJson {
        title
        description
        section1 {
          headerText
          subHeaderText
          ctaText
          ctaUrl
        }
        section2 {
          headerText
          subHeaderText
          copy
          ctaText
          ctaUrl
          image {
            childImageSharp {
              fluid(maxWidth: 1920, toFormat: PNG) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          # items {
          #   image {
          #     childImageSharp {
          #       fluid(maxWidth: 1920, toFormat: PNG) {
          #         ...GatsbyImageSharpFluid_noBase64
          #       }
          #     }
          #   }
          #   headerText
          #   ctaText
          #   ctaHintText
          #   ctaUrl
          #   features {
          #     headerText
          #     descriptionText
          #   }
          # }
        }
        section3 {
          headerText
          copy
          ctaText
          ctaUrl
        }
      }
    }
    backgroundImage: file(relativePath: {eq: "background-products-item.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: PNG, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
